
import {
  defineComponent,
  reactive,
  ref
} from 'vue'
import DefaultTable from '@/views/shared-components/table/Default.vue'
import ColumActive from '@/views/shared-components/column/ColumnActive.vue'
import { useRoute, useRouter } from 'vue-router'
import { TResultMsUsers } from '@/data/source/remote/api/identity/V2/msUsers-model'
import OverlayPanel from 'primevue/overlaypanel'

const endpoint = '/identity/v2/msusers'

export default defineComponent({
  name: 'ListUser',
  components: {
    DefaultTable,
    ColumActive,
    OverlayPanel
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const dataForm = ref<TResultMsUsers>()
    const op = ref<InstanceType<typeof OverlayPanel>>()
    const paramsTable = reactive({
      endpoint,
      columns: [{
        field: 'Fullname',
        headerName: 'Nama'
      },
      {
        field: 'Username',
        headerName: 'Username'
      },
      {
        field: 'Email',
        headerName: 'Email'
      },
      {
        field: 'GroupUserNama',
        headerName: 'Group User',
      },
      {
        field: 'IsActive',
        headerName: 'Aktif'
      },
      {
        field: 'Action',
        headerName: 'Aksi',
        style: {
          width: '150px'
        }
      }],
      showColumnActivate: true,
      labelBtnAdd: 'Tambah User',
      title: 'Daftar User',
      fieldSearch: ['fullname']
    })

    const showDropDown = (evt: Event, rowData: TResultMsUsers) => {
      dataForm.value = rowData
      // eslint-disable-next-line no-unused-expressions
      op.value?.toggle(evt)
    }

    const onEditData = () => {
      router.push({
        name: 'master-user-edit',
        params: {
          userId: dataForm.value?.Id as any
        }
      })
    }

    const onAdd = () => {
      router.push({
        name: 'master-user-add'
      })
    }
    return {
      paramsTable,
      endpoint,
      route,
      showDropDown,
      op,
      onEditData,
      onAdd
    }
  }
})
